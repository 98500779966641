<template>
  <Layout>
    <template v-if="this.$route.name == 'add-speciality'">
      <PageHeader :title="title1" :items="items"></PageHeader>
    </template>
    <template v-else>
      <PageHeader :title="title2" :items="items"></PageHeader>
    </template>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <form-wizard @on-complete="submitData" color="#556ee6" enctype="multipart/form-data" ref="speciality"
              :finishButtonText="this.$route.name == 'add-speciality' ? 'Save Data' : 'Update Data'"
              back-button-text="Go Back!" next-button-text="Go Next!">
              <tab-content title="Speciality Details" icon="mdi mdi-account-details"
                :before-change="() => validateFormOne()">
                <div class="row">
                  <b-form-group id="input-group-1" class="col-12">
                    <label for="input-1">Speciality Title <span style="color: red">*</span></label>
                    <b-form-input id="input-1" v-model="form.title" placeholder="Enter Speciality Title" :class="{
                      'is-invalid': submitted && $v.form.title.$invalid,
                    }"></b-form-input>
                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">
                      Speciality Title is required.
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-3" label="Tell Us More About Speciality" label-for="input-3"
                    class="position-relative col-12">
                    <ckeditor v-model="form.description" :maxlength="250">
                    </ckeditor>
                    <div class="text-right">
                      <p v-if="form.description" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.description.length !== 250,
                        'badge-danger': form.description.length === 250
                      }">
                        You typed
                        {{ form.description.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-di" class="col-6">
                    <label for="input-di">Speciality Display Name <span style="color: red">*</span></label>
                    <b-form-input id="input-di" v-model="form.display_name" placeholder="Enter Speciality Display Name"
                      :class="{
                        'is-invalid': submitted && $v.form.display_name.$invalid,
                      }"></b-form-input>
                    <div v-if="submitted && !$v.form.display_name.required" class="invalid-feedback">
                      Speciality Display Name is required.
                    </div>
                  </b-form-group>
                  <b-form-group id="input-di" class="col-6">
                    <label for="input-di">Community Display Name <span style="color: red">*</span></label>
                    <b-form-input id="input-di" v-model="form.community_display_name"
                      placeholder="Enter Community Display Name" :class="{
                        'is-invalid': submitted && $v.form.community_display_name.$invalid,
                      }"></b-form-input>
                    <div v-if="submitted && !$v.form.community_display_name.required" class="invalid-feedback">
                      Community Display Name is required.
                    </div>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="input-3">Image [Upload Max File Size : 2MB] <span style="color: red;">*</span></label>
                    <b-form-file id="image" accept="image/*" placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'image')" ref="image" :class="{
                        'is-invalid': submitted && $v.image.$invalid,
                      }">
                    </b-form-file>
                    <template v-if="$route.name == 'edit-speciality' && edit.image_url">
                      <img :src="edit.image_url" width="128px" height="128px"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <template v-if="image_url">
                      <img :src="image_url" width="128px" height="128px" ref="image_url"
                        style="object-fit: contain; margin-top: 5px" />
                    </template>
                    <div v-if="submitted && !$v.image.required" class="invalid-feedback">
                      Image is required.
                    </div>
                  </b-form-group>
                </div>
              </tab-content>

              <tab-content title="Add Tags" icon="fa fa-tag" :before-change="() => validateFormTwo()">
                <div class="row">
                  <b-form-group class="col-6 position-relative" id="input-group-4" label="Member Type"
                    label-for="input-4">
                    <b-form-input id="input-4" v-model="form.member_type" placeholder="Enter Member Type"
                      :maxlength="250"></b-form-input>
                    <div class="text-right">
                      <p v-if="form.member_type" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                        'badge-success': form.member_type.length !== 250,
                        'badge-danger': form.member_type.length === 250
                      }">
                        You typed
                        {{ form.member_type.length }} out of 250 chars.
                      </p>
                    </div>
                  </b-form-group>
                  <b-form-group id="input-group-comm" class="col-6">
                    <label for="input-comm">Community <span style="color: red">*</span></label>
                    <multiselect @search-change="fetchCommunity" placeholder="Type here to search" id="input-com"
                      v-model="form.community_id" :options="community" :multiple="false" track-by="id" label="title">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                  </b-form-group>
                </div>

                <div class="row">
                  <b-form-group label-for="input-com" label="Community Mapping" class="col-6">
                    <multiselect @search-change="fetchCommunity" id="input-com" v-model="form.communityMap"
                      :options="community" :multiple="true" track-by="id" label="title">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                  </b-form-group>
                  <b-form-group class="col-6">
                    <label for="input-spec">Sub Speciality Mapping
                      <span style="color: red">*</span></label>
                    <multiselect @search-change="fetchSubSpeciality" id="input-spec" v-model="form.subSpeciality"
                      :options="subspeciality" :multiple="true" track-by="id" label="name">
                      <span slot="noOptions">
                        Type here to search
                      </span>
                    </multiselect>
                  </b-form-group>
                </div>
                <b-form-group label-for="input-regi">
                  <b-form-checkbox id="input-regi" v-model="form.is_available_for_registration">Is Available for
                    registration</b-form-checkbox>
                </b-form-group>
              </tab-content>
            </form-wizard>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import specialityMixin from "../../../mixins/ModuleJs/speciality";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";

export default {
  data() {
    return {
      submitted: false,
      store: false,
      title1: "Add Speciality",
      title2: "Edit Speciality",
      items: [
        {
          text: "Back",
          href: "/speciality",
        },
        {
          text: "Data",
        },
      ],
    };
  },
  mixins: [MixinRequest, specialityMixin],
  components: {
    Layout,
    PageHeader,
    Multiselect,
    FormWizard,
    TabContent
  },
  validations: {
    form: {
      title: { required },
      display_name: { required },
      community_display_name: { required },
    },
    image: { required }
  },
  methods: {
    validateFormOne() {
      this.submitted = true;
      if (this.$v.form.title.$invalid || this.$v.form.display_name.$invalid || this.$v.form.community_display_name.$invalid || this.$v.image.$invalid) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Required Fields"
        });
        return false;
      }
      return true;
    },
    validateFormTwo() {
      this.store = true;
      // if (this.$v.form.subSpeciality.$invalid || this.$v.form.community_id.$invalidd) {
      //   this.$store.commit("toast/updateStatus", {
      //     status: true,
      //     icon: "error",
      //     title: "Please Fill The Required Fields"
      //   });
      //   return false;
      // }
      return true;
    },
  },
};
</script>
